














import { Component } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'
import { CustomLink } from '../../../dsl/atoms/Link'
import { ImageProps } from '../../../dsl/atoms/Image'
import { toImageProps } from '../../../front/shared/support'

import { UiMarkdown } from '../../partials/UiMarkdown'

import { CTABoxModule } from '../CTABox.contracts'

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component({ name: 'CTABoxModuleUiLinked', components: { UiMarkdown, CustomLink } })
export class CTABoxModuleUi extends AbstractModuleUi<CTABoxModule> {
  public get direction (): string {
    return this.content.direction
  }

  public get image (): ImageProps {
    if (!this.content.image) {
      throw new Error('CTABoxUi: Image is null!')
    }

    return toImageProps(this.content.image)
  }

  public get headline (): string {
    return this.content.headline
  }

  public get link () {
    return this.content.cta?.action
  }

  public get text (): string {
    return this.content.content
  }
}

export default CTABoxModuleUi
